import { useEffect } from "react";
import { usePage } from "./usePage";

export const useTrackEventLoad = (event: string) => {
  const { trackEvent } = usePage();

  useEffect(() => {
    trackEvent(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
