import React from "react";
import { Flex } from "@src/components/Boxes";
import { Typography } from "@src/components/Typography";
import { mq } from "@src/components/FacePaintResponsive";
import { NextButton } from "./InputStepTemplate";
import { SharedStepTemplate } from "./SharedStepTemplate";
import { useTranslations } from "../hooks/useTranslations";
import { useAssets } from "../hooks/useAssets";
import { MojoBadge } from "./MojoBadege";
import { useTrackEventLoad } from "../hooks/useTrackEventLoad";

export const LastStep = () => {
  useTrackEventLoad("submit_success_page_view");
  const {
    mentorsSteps: { last_step_title, last_step_text, having_trouble_text, contact_support },
  } = useTranslations();
  const a = useAssets();

  return (
    <SharedStepTemplate
      imageCss={mq({ backgroundPosition: "center" })}
      sticker={{ img: a.heartSticker, css: { top: 100, right: 100 } }}
      body={
        <>
          <MojoBadge />
          <Flex flexDirection="column" gap={24} alignSelf="center" flex={1} justifyContent="center">
            <Typography variant="Display4ExtraBold">{last_step_title}</Typography>
            <Typography variant="Body1" css={{ color: "#5D5D8F" }}>
              {last_step_text}
            </Typography>
            <Typography variant="Body1">
              {having_trouble_text} <a href="/">{contact_support}</a>
            </Typography>
            <Flex gap={12}>
              <NextButton href="/" css={mq({ flex: [0, 0] })}>
                ClassDojo Mentors
              </NextButton>
            </Flex>
          </Flex>
        </>
      }
      image={a.step_9_image}
    />
  );
};
