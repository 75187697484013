import { GQLImage } from "@src/components/whats-new-sl/context";
import { graphql, useStaticQuery } from "gatsby";

type Asset<T extends string> = {
  [key in T]: GQLImage;
};

export type AssetsList = Asset<
  | "mojo"
  | "bottom"
  | "backpack"
  | "magic"
  | "rocket"
  | "monster"
  | "cone"
  | "step_1_image"
  | "step_2_image"
  | "step_3_image"
  | "step_4_image"
  | "step_5_image"
  | "step_6_image"
  | "step_7_image"
  | "step_8_image"
  | "step_9_image"
  | "smile"
  | "clapSticker"
  | "emojiStarSticker"
  | "heartSticker"
  | "heartsSticker"
  | "mentorBadge"
  | "musicSticker"
  | "partySticker"
  | "schoolSticker"
  | "smileSticker"
  | "starsSticker"
  | "arrowLeft"
>;

export const useAssets = (): AssetsList => {
  const data = useStaticQuery(graphql`
    {
      smile: file(relativePath: { eq: "become-mentor-application/smile.png" }) {
        publicURL
      }
      mojo: file(relativePath: { eq: "mentors2/mojo.svg" }) {
        publicURL
      }
      bottom: file(relativePath: { eq: "mentors2/bottom.png" }) {
        publicURL
      }
      backpack: file(relativePath: { eq: "mentors2/backpack.svg" }) {
        publicURL
      }
      magic: file(relativePath: { eq: "mentors2/magic.svg" }) {
        publicURL
      }
      rocket: file(relativePath: { eq: "mentors2/rocket.svg" }) {
        publicURL
      }
      monster: file(relativePath: { eq: "mentors2/monster.svg" }) {
        publicURL
      }
      cone: file(relativePath: { eq: "mentors2/cone.png" }) {
        publicURL
      }

      step_1_image: file(relativePath: { eq: "become-mentor-application/step1.jpeg" }) {
        publicURL
      }
      step_2_image: file(relativePath: { eq: "become-mentor-application/step2.jpeg" }) {
        publicURL
      }
      step_3_image: file(relativePath: { eq: "become-mentor-application/step3.jpeg" }) {
        publicURL
      }
      step_4_image: file(relativePath: { eq: "become-mentor-application/step4.jpeg" }) {
        publicURL
      }
      step_5_image: file(relativePath: { eq: "become-mentor-application/step5.jpeg" }) {
        publicURL
      }
      step_6_image: file(relativePath: { eq: "become-mentor-application/step6.jpeg" }) {
        publicURL
      }
      step_7_image: file(relativePath: { eq: "become-mentor-application/step7.png" }) {
        publicURL
      }
      step_8_image: file(relativePath: { eq: "become-mentor-application/step8.jpeg" }) {
        publicURL
      }
      step_9_image: file(relativePath: { eq: "become-mentor-application/step9.jpeg" }) {
        publicURL
      }

      clapSticker: file(relativePath: { eq: "become-mentor-application/clap.svg" }) {
        publicURL
      }
      emojiStarSticker: file(relativePath: { eq: "become-mentor-application/emoji-star.svg" }) {
        publicURL
      }
      heartSticker: file(relativePath: { eq: "become-mentor-application/heart.svg" }) {
        publicURL
      }
      heartsSticker: file(relativePath: { eq: "become-mentor-application/hearts.svg" }) {
        publicURL
      }
      mentorBadge: file(relativePath: { eq: "become-mentor-application/mentor-badge.svg" }) {
        publicURL
      }
      musicSticker: file(relativePath: { eq: "become-mentor-application/music.svg" }) {
        publicURL
      }
      partySticker: file(relativePath: { eq: "become-mentor-application/party.svg" }) {
        publicURL
      }
      smileSticker: file(relativePath: { eq: "become-mentor-application/smile.svg" }) {
        publicURL
      }
      schoolSticker: file(relativePath: { eq: "become-mentor-application/school.svg" }) {
        publicURL
      }
      starsSticker: file(relativePath: { eq: "become-mentor-application/stars.svg" }) {
        publicURL
      }
      arrowLeft: file(relativePath: { eq: "become-mentor-application/arrowLeft.svg" }) {
        publicURL
      }
    }
  `);

  return data;
};
