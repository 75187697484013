import { Flex } from "@src/components/Boxes";
import React, { useContext } from "react";
import LoginForm from "@src/components/modals/LoginForm";
import { useTrackEventLoad } from "../hooks/useTrackEventLoad";
import { Typography } from "@src/components/Typography";
import { useAssets } from "../hooks/useAssets";
import { SharedStepTemplate } from "./SharedStepTemplate";
import { mq } from "@src/components/FacePaintResponsive";
import { AppDataContext } from "@src/components/AppDataContext";

export const TryToLogin = () => {
  useTrackEventLoad("login_load");
  const a = useAssets();

  return (
    <Flex
      py={20}
      px={20}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="100vh"
    >
      <SharedStepTemplate
        imageCss={mq({ backgroundPosition: "center" })}
        image={a.step_9_image}
        body={
          <Flex
            flexDirection="row"
            flex={1}
            css={{ padding: 25, borderRadius: 10 }}
            justifyContent="center"
            alignSelf="center"
          >
            <Flex
              flexDirection="column"
              gap={24}
              css={{ maxWidth: "500px", padding: "10px", marginTop: "100px", marginBottom: "100px" }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="Display4ExtraBold">Login to apply as a mentor</Typography>
              <LoginForm userType="teacher" noRedirect showCodeLogin />
            </Flex>
          </Flex>
        }
      />
    </Flex>
  );
};
