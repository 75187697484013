import { LocalStorageWrapper } from "@src/utils/localStorage";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useWizard } from "react-use-wizard";
import { MentorApplicationFormValues, key } from "./MentorApplicationForm";

export const PersistForm = () => {
  const { activeStep } = useWizard();
  const { values } = useFormikContext<MentorApplicationFormValues>();
  useEffect(() => {
    LocalStorageWrapper.setItem(key, JSON.stringify({ ...values, activeStep }));
  }, [values, activeStep]);

  return null;
};
