import styled from "@emotion/styled";
import { Flex } from "@src/components/Boxes";
import { mq } from "@src/components/FacePaintResponsive";
import { GImage } from "@src/components/GImage";
import { Button } from "@src/components/nessie-web";
import { Typography } from "@src/components/Typography";
import React from "react";
import { useAssets } from "../hooks/useAssets";
import { Progress } from "./Progress";

interface StepsHeaderProps {
  steps: number;
  currentSteps: number;
  onBack: () => void;
}

const BackButton = styled(Button)`
  background-color: ${(props) => props.theme.__new.colors.backgroundAccent};
  border: 0px solid ${(props) => props.theme.__new.colors.contentAccent};
  span {
    color: ${(props) => props.theme.__new.colors.contentAccent};
  }
`;

interface MobileHeaderProps {
  onBack: () => void;
}

const MobileHeader = ({ onBack }: MobileHeaderProps) => {
  const a = useAssets();

  return (
    <Flex
      flexDirection="column"
      my={2}
      css={mq({ display: ["block", "none"], borderBottom: "1px solid #eee", paddingBottom: 15, marginBottom: 15 })}
    >
      <Flex alignItems="center" justifyContent="center">
        <Typography variant="Display6ExtraBold" onClick={onBack} css={{ pointer: "cursor" }}>
          <GImage img={a.arrowLeft} alt="back" />
        </Typography>
        <Typography variant="Display6ExtraBold" css={mq({ display: ["block", "none"], textAlign: "center", flex: 1 })}>
          Apply
        </Typography>
      </Flex>
    </Flex>
  );
};

const StepsHeader = ({ onBack, steps, currentSteps }: StepsHeaderProps) => {
  const a = useAssets();
  return (
    <Flex flexDirection="column" my={2}>
      {/* <MobileHeader onBack={onBack} /> */}
      <Flex alignItems="center" gap={12}>
        <BackButton onClick={onBack} css={mq({ display: ["none", "block"] })}>
          <GImage img={a.arrowLeft} alt="back" />
        </BackButton>
        <Progress value={currentSteps} max={steps} />
      </Flex>
    </Flex>
  );
};

export default StepsHeader;
