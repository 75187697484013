import styled from "@emotion/styled";
import { Flex } from "@src/components/Boxes";
import React from "react";
import SEO from "@src/components/SEO";
import { MentorApplicationSteps } from "@src/components/partials/become-mentor-application/components/MentorApplicationSteps";
import { PageContext } from "../../components/partials/become-mentor-application/hooks/usePage";
import { Authenticated } from "../../components/partials/become-mentor-application/components/Authenticated";
import { useTrackEventLoad } from "@src/components/partials/become-mentor-application/hooks/useTrackEventLoad";
import MentorApplicationForm from "@src/components/partials/become-mentor-application/components/MentorApplicationForm";

const ContainerStyled = styled(Flex)``;

function Mentors() {
  useTrackEventLoad("page_view");

  return (
    <Authenticated>
      <ContainerStyled flexDirection={["column", "row"]} height="100vh" width="100vw">
        <MentorApplicationForm>
          <Flex flexDirection="column" flex={1} padding={[0, 55]} gap={24}>
            <MentorApplicationSteps />
          </Flex>
        </MentorApplicationForm>
      </ContainerStyled>
    </Authenticated>
  );
}

export default function Page() {
  return (
    <PageContext.Provider
      value={{
        translateBaseKey: "directus.mentors_flow",
        logEventBaseKey: "web.external_page.become_mentor_application",
      }}
    >
      <SEO noindex />
      <Mentors />
    </PageContext.Provider>
  );
}
