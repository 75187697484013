import { useContext, useEffect } from "react";
import { AppDataContext } from "@src/components/AppDataContext";
import { TranslationContext } from "@src/components/translation/TranslationContext";

export const useGetEligibilityMessage = () => {
  const appData = useContext(AppDataContext);
  const t = useContext(TranslationContext);
  useEffect(() => {
    if (appData.data.userData && !appData.data.mentorApplicationEligibility) {
      appData.services.getMentorApplicationEligibility();
    }
  }, [appData.data, appData.services]);

  if (!appData.data.mentorApplicationEligibility) {
    return null;
  }

  let title: string;
  const mentors = appData.data.mentorApplicationEligibility.mentors;
  const applied = appData.data.mentorApplicationEligibility.applied as boolean;
  const isEligible = appData.data.mentorApplicationEligibility.isEligible as boolean;

  const getReason = () => {
    switch (appData.data.mentorApplicationEligibility.reason) {
      case "Teacher is not in a school":
        return {
          reason: t.translate("directus.page_mentors_surveys.error_no_school"),
          status: "error",
        };
      case "Teacher is not in verified in their school":
        return {
          reason: "Teacher is not in verified in their school",
          status: "error",
        };
      case "User is not a teacher":
        return {
          reason: t.translate("directus.page_mentors_surveys.error_parent"),
          status: "error",
        };
      case "Teacher is already a mentor":
        return { reason: t.translate("directus.page_mentors_surveys.already_mentor"), status: "error" };
      case "Teacher is school leader":
        return { reason: t.translate("directus.page_mentors_surveys.error_school_leader"), status: "error" };
      case "School already has 3+ mentors":
        return { reason: t.translate("directus.page_mentors_surveys.error_too_many_mentors"), status: "error" };
      case "User has an existing mentor application":
        return { reason: t.translate("directus.page_mentors_surveys.error_existing_application"), status: "success" };
      default:
        return { reason: null, status: "success" };
    }
  };

  if (appData.data.mentorApplicationEligibility.isEligible) {
    title = t.translate("directus.page_mentors_surveys.almost_there").toString();
  } else {
    title =
      appData.data.mentorApplicationEligibility.reason === "Teacher is already a mentor"
        ? t.translate("directus.page_mentors_surveys.already_mentor_title").toString()
        : t.translate("directus.page_mentors_surveys.almost_there").toString();
  }

  const { reason, status } = getReason();

  return { title, applied, reason, mentors, isEligible, status };
};

export type EligibilityReturnType = ReturnType<typeof useGetEligibilityMessage>;
