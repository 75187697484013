import styled from "@emotion/styled";
import React from "react";

interface ProgressProps {
  value: number;
  max: number;
  [key: string]: any;
}
const ProgressStyled = styled.progress`
  width: 100%;
  height: 20px;
  appearance: none;

  &::-webkit-progress-bar {
    background-color: #e0e0e0;
    border-radius: 10px;
  }

  &::-webkit-progress-value {
    background-color: ${(props) => props.theme.__new.colors.contentAccent};
    border-radius: 10px;
  }

  &::-moz-progress-bar {
    background-color: #3b5998;
    border-radius: 10px;
  }
`;
export const Progress = ({ value, max, ...rest }: ProgressProps) => {
  return <ProgressStyled value={value} max={max} {...rest} />;
};
