import { AppDataContext } from "@src/components/AppDataContext";
import { Flex } from "@src/components/Boxes";
import { LocalStorageWrapper } from "@src/utils/localStorage";
import { Formik, FormikHelpers } from "formik";
import React, { ReactNode, useContext, useState } from "react";

import * as y from "yup";
import { useGetStepsData } from "../hooks/useGetStepsData";
import { LastStep } from "./LastStep";

const INITIAL_VALUES = {
  introduceYourself: "",
  schoolCommunity: "",
  describeClassDojo: "",
  collaboration: "",
  whyExcitedToBeMentor: "",
  mentorPlans: "",
  howManyTeachers: "",
};

export type MentorApplicationFormValues = typeof INITIAL_VALUES;

const SCHEMA: y.ObjectSchema<MentorApplicationFormValues> = y.object().shape({
  introduceYourself: y.string().required("This field is required"),
  schoolCommunity: y.string().required("This field is required"),
  describeClassDojo: y.string().required("This field is required"),
  collaboration: y.string().required("This field is required"),
  whyExcitedToBeMentor: y.string().required("This field is required"),
  mentorPlans: y.string().required("This field is required"),
  howManyTeachers: y.string().required("This field is required"),
});

const useSubmit = () => {
  const { steps } = useGetStepsData();
  const { services } = useContext(AppDataContext);

  return async (values: MentorApplicationFormValues) => {
    const questionsPayload = steps.map((step) => ({
      questionText: step.text,
      answer: values[step.field],
    }));

    const payload = {
      agreement: true,
      questions: questionsPayload,
      status: "pending",
    };

    await services.applyToBeMentor(payload);
  };
};

export const key = "mentorApplicationData";

export const useLoadPersistedForm = () => {
  const [state] = useState(() => {
    const savedData = LocalStorageWrapper.getItem(key);

    return savedData ? JSON.parse(savedData) : INITIAL_VALUES;
  });

  return state;
};

const MentorApplicationForm = ({ children }: { children: ReactNode }) => {
  const [submitted, setSubmitted] = useState(false);
  const submit = useSubmit();
  const initialValues = useLoadPersistedForm();

  const handleSubmit = async (
    values: MentorApplicationFormValues,
    helper: FormikHelpers<MentorApplicationFormValues>,
  ) => {
    await submit(values);
    helper.setSubmitting(false);
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <Flex py={20} px={20} justifyContent="center" alignItems="center" flexDirection="column" width="100%">
        <LastStep />
      </Flex>
    );
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={SCHEMA}>
      {children}
    </Formik>
  );
};

export default MentorApplicationForm;
