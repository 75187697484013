import React, { ReactNode, useEffect, useRef } from "react";
import { TextArea } from "@src/components/nessie-web";
import { GQLImage } from "@src/components/whats-new-sl/context";
import { ImageCss } from "../components/MentorApplicationSteps";
import { useAssets } from "./useAssets";
import { ErrorMessage, Field, FormikProps } from "formik";
import { MentorApplicationFormValues } from "../components/MentorApplicationForm";
import { Flex } from "@src/components/Boxes";
import styled from "@emotion/styled";
import { useTranslations } from "./useTranslations";
import { Select } from "@src/components/modals/MentorsSurveys/joinTheClub.style";

export interface Step<T> {
  title: string;
  text: string;
  image: GQLImage;
  input: ReactNode;
  imageCss: ImageCss;
  sticker?: {
    img: GQLImage;
    css: {
      top?: number | string;
      bottom?: number | string;
      right?: number | string;
      left?: number | string;
    };
  };
  field: keyof T;
}

interface UseGetStepsReturnValue {
  steps: Step<MentorApplicationFormValues>[];
}

const ErrorMessageStyled = styled(ErrorMessage)`
  padding: 24px;
  border-radius: 15px;
  color: ${(props) => props.theme.__new.colors.contentDanger};
  background-color: ${(props) => props.theme.__new.colors.backgroundDanger};
`;

interface FormikTextAreaProps {
  form: FormikProps<MentorApplicationFormValues>;
  [key: string]: any;
}

const FormikTextArea = (props: FormikTextAreaProps) => {
  const { field, form, ...rest } = props;
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <Flex flexDirection="column" gap={24}>
      <TextArea autoFocus ref={ref} {...field} {...rest} onChange={(value) => form.setFieldValue(field.name, value)} />
      <Flex height={71} flexDirection="column">
        <ErrorMessageStyled name={field.name} component="div" />
      </Flex>
    </Flex>
  );
};

interface FormikSelectProps {
  form: FormikProps<MentorApplicationFormValues>;
  [key: string]: any;
}

const FormikSelect = (props: FormikSelectProps) => {
  const { field, form, ...rest } = props;

  const ref = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <Select required ref={ref} {...field}>
      <option value="">Select number of teachers</option>
      <option value="1-30">1-30</option>
      <option value="30-60">30-60</option>
      <option value="60-120">60-120</option>
      <option value="120+">120+</option>
    </Select>
  );
};

export const useGetStepsData = (): UseGetStepsReturnValue => {
  const t = useTranslations();
  const a = useAssets();

  const stepProps = (key: number) => {
    return {
      title: t.mentorsSteps[`step_${key}_title`],
      text: t.mentorsSteps[`step_${key}_text`],
      image: a[`step_${key + 1}_image`],
    };
  };

  const steps: Step<MentorApplicationFormValues>[] = [
    {
      ...stepProps(1),
      imageCss: { backgroundPosition: "center" },
      field: "introduceYourself",
      input: <Field name="introduceYourself" required component={FormikTextArea} />,
      sticker: { img: a.starsSticker, css: { bottom: "10%", right: "10%" } },
    },
    {
      ...stepProps(2),
      imageCss: { backgroundPosition: "center" },
      field: "schoolCommunity",
      input: <Field name="schoolCommunity" required component={FormikTextArea} />,
      sticker: { img: a.schoolSticker, css: { bottom: "-5%", left: "40%" } },
    },
    {
      ...stepProps(3),
      imageCss: { backgroundPosition: "center" },
      field: "describeClassDojo",
      input: <Field name="describeClassDojo" required component={FormikTextArea} />,
      sticker: { img: a.heartsSticker, css: { top: "10%", right: "10%" } },
    },
    {
      ...stepProps(4),
      imageCss: { backgroundPosition: "center" },
      field: "collaboration",
      input: <Field name="collaboration" required component={FormikTextArea} />,
      sticker: { img: a.emojiStarSticker, css: { top: "50%", right: "50%" } },
    },
    {
      ...stepProps(5),
      imageCss: { backgroundPosition: "center" },
      field: "whyExcitedToBeMentor",
      input: <Field name="whyExcitedToBeMentor" required component={FormikTextArea} />,
      sticker: { img: a.smileSticker, css: { bottom: "10%", left: "10%" } },
    },
    {
      ...stepProps(6),
      imageCss: { backgroundPosition: "center" },
      field: "mentorPlans",
      input: <Field name="mentorPlans" required component={FormikTextArea} />,
      sticker: { img: a.partySticker, css: { bottom: "5%", left: "5%" } },
    },
    {
      ...stepProps(7),
      imageCss: { backgroundPosition: "center" },
      field: "howManyTeachers",
      input: <Field name="howManyTeachers" required component={FormikSelect} />,
      sticker: { img: a.musicSticker, css: { top: "-5%%", left: "-5%" } },
    },
  ];

  return { steps };
};
