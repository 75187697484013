import { TranslationContext } from "@src/components/translation/TranslationContext";
import { createContext, useCallback, useContext } from "react";
import { logEvent } from "@src/utils/logClient";

interface PageContextProps {
  translateBaseKey: string;
  logEventBaseKey: string;
}
export const PageContext = createContext<PageContextProps | null>(null);

export const usePage = () => {
  const settings = useContext(PageContext);

  if (!settings) throw new Error("usePage must be used within a PageProvider");

  const { translateBaseKey, logEventBaseKey } = settings;
  const t = useContext(TranslationContext);

  const translate = useCallback(
    (suffix: string, object?: Record<string, string>) => {
      return t.translate(`${translateBaseKey}.${suffix}`, object);
    },
    [translateBaseKey, t],
  );

  const trackEvent = useCallback(
    (eventSuffix: string, data?: object) => {
      logEvent({
        eventValue: window.location.href,
        ...data,
        eventName: `${logEventBaseKey}.${eventSuffix}`,
      });
    },
    [logEventBaseKey],
  );

  const decorateWithTrack = useCallback(
    (fn: () => void, eventSuffix: string) => {
      return () => {
        trackEvent(eventSuffix);
        fn();
      };
    },
    [trackEvent],
  );

  return {
    translate,
    trackEvent,
    decorateWithTrack,
  };
};
