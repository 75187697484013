import React from "react";
import { Flex } from "@src/components/Boxes";
import { Typography } from "@src/components/Typography";
import { useWizard } from "react-use-wizard";
import { mq } from "@src/components/FacePaintResponsive";
import { AlertInfo, NextButton } from "./InputStepTemplate";
import { SharedStepTemplate } from "./SharedStepTemplate";
import { usePage } from "../hooks/usePage";
import { useTranslations } from "../hooks/useTranslations";
import { useAssets } from "../hooks/useAssets";
import { MojoBadge } from "./MojoBadege";

interface FirstStepProps {
  showStart: boolean;
}

export const FirstStep = ({ showStart }: FirstStepProps) => {
  const t = useTranslations();
  const a = useAssets();

  const title = t.mentorsSteps.first_step_title;
  const text = t.mentorsSteps.first_step_text_large;
  const alertText = t.mentorsSteps.first_step_alert_text;
  const image = a.step_1_image;
  const imageCss = { backgroundPosition: "center" };

  const { decorateWithTrack } = usePage();
  const { nextStep } = useWizard();

  const nextStepWithTrack = decorateWithTrack(nextStep, "steps.start_click");

  return (
    <SharedStepTemplate
      imageCss={mq(imageCss)}
      sticker={{ img: a.clapSticker, css: { top: "10%", left: "10%" } }}
      body={
        <>
          <MojoBadge />
          <Flex
            flexDirection="column"
            flex={1}
            gap={24}
            justifyContent={["flex-start", "center", "center"]}
            alignSelf="center"
          >
            <Typography variant="Display4ExtraBold">{title}</Typography>
            <Typography variant="Body1" css={{ color: "#5D5D8F", textWrap: "wrap" }}>
              {text}
            </Typography>
            <AlertInfo>{alertText}</AlertInfo>
          </Flex>
          {showStart && (
            <Flex>
              <NextButton css={mq({ flexGrow: [1, "none"] })} onClick={nextStepWithTrack}>
                Start
              </NextButton>
            </Flex>
          )}
        </>
      }
      image={image}
    />
  );
};
