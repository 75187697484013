import { AppDataContext } from "@src/components/AppDataContext";
import { useContext } from "react";
import { usePage } from "./usePage";

export const useTranslations = () => {
  const { data: appData } = useContext(AppDataContext);
  const { translate } = usePage();

  return {
    mentorsSteps: {
      first_step_title: translate("first_step_title", { name: appData.userData?.firstName }).toString(),
      first_step_text_large: translate("first_step_text_large").toString(),
      first_step_alert_text: translate("first_step_alert_text").toString(),

      step_1_title: translate("step_1_title").toString(),
      step_1_text: translate("step_1_text").toString(),
      step_2_title: translate("step_2_title").toString(),
      step_2_text: translate("step_2_text").toString(),
      step_3_title: translate("step_3_title").toString(),
      step_3_text: translate("step_3_text").toString(),
      step_4_title: translate("step_4_title").toString(),
      step_4_text: translate("step_4_text").toString(),
      step_5_title: translate("step_5_title").toString(),
      step_5_text: translate("step_5_text").toString(),
      step_6_title: translate("step_6_title").toString(),
      step_6_text: translate("step_6_text").toString(),
      step_7_title: translate("step_7_title").toString(),
      step_7_text: translate("step_7_text").toString(),

      last_step_title: translate("last_step_title").toString(),
      last_step_text: translate("last_step_text").toString(),
      last_step_button_text: translate("last_step_button_text").toString(),

      having_trouble_text: translate("having_trouble_text").toString(),
      contact_support: translate("contact_support").toString(),
    },
    authenticated: {
      text: translate("ineligible_text", { name: appData.userData?.firstName }).toString(),
      buttonText: translate("ineligible_button_text").toString(),
      // image: data.cone,
      loginText: translate("try_to_login_text").toString(),
      loginButtonText: translate("try_to_login_button_text").toString(),
    },
  };
};
