import React from "react";
import MentorAlert, { MentorAlertBody } from "./MentorAlert";
import { GQLImage } from "@src/components/whats-new-sl/context";
import { TranslationType } from "@src/components/translation/TranslationContext";

interface EligibilityMessageProps {
  warningImg: GQLImage;
  successImg: GQLImage;
  reason: TranslationType;
  status: string;
}

export const EligibilityMessage = ({ warningImg, successImg, reason, status }: EligibilityMessageProps) => {
  const variant = status === "success" ? "success" : "warning";
  const img = status === "success" ? successImg : warningImg;

  return (
    <MentorAlert variant={variant} title="Important" body={<MentorAlertBody>{reason}</MentorAlertBody>} icon={img} />
  );
};
