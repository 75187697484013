import { AppDataContext } from "@src/components/AppDataContext";
import React, { useContext, useEffect } from "react";
import { TryToLogin } from "./TryToLogin";

interface AuthenticatedProps {
  children: React.ReactNode;
}
export const Authenticated = ({ children }: AuthenticatedProps) => {
  const appData = useContext(AppDataContext);

  useEffect(() => {
    if (appData.data.userData && !appData.data.mentorApplicationEligibility) {
      appData.services.getMentorApplicationEligibility();
    }
  }, [appData]);

  if (!appData || !appData.data) return null;

  if (appData.data.loadingSession) return null;

  if (!appData.data.userData) return <TryToLogin />;

  return <>{children}</>;
};
