import React, { ReactNode } from "react";
import { Flex } from "@src/components/Boxes";
import { GQLImage } from "@src/components/whats-new-sl/context";
import { GImage } from "@src/components/GImage";
import { keyframes } from "@emotion/react";
import { mq } from "@src/components/FacePaintResponsive";

interface SharedStepTemplateProps {
  body: ReactNode;
  image: GQLImage;
  imageCss?: any;
  sticker?: {
    img: GQLImage;
    css: {
      top?: number | string;
      bottom?: number | string;
      right?: number | string;
      left?: number | string;
    };
  };
}

const wiggle = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%); /* Start off the screen on the right */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* Slide into the normal position */
  }
`;

export const SharedStepTemplate = ({ body, image, imageCss, sticker }: SharedStepTemplateProps) => {
  return (
    <>
      <Flex flexDirection={["column-reverse", "row"]} width="100%" gap={24} flex={1}>
        <Flex flexDirection="column" flex={1} px={12} py={[12, 0, 0]} gap={24}>
          {body}
        </Flex>
        <Flex
          flex={1}
          alignItems="flex-start"
          justifyContent="flex-start"
          css={mq({
            ...imageCss,
            animation: `${slideIn} 1s forwards`,
            position: "relative",
            backgroundSize: "cover",
            borderRadius: "40px",
            backgroundImage: `url(${image.publicURL})`,
            backgroundPosition: "center",
            display: ["none", "block"],
          })}
          margin={[12, 0]}
        >
          {sticker?.img && (
            <GImage
              img={sticker.img}
              alt="sticker"
              css={{
                position: "absolute",
                animation: `${slideIn} 1s forwards, ${wiggle} 0.5s ease-in-out 1s`,
                ...sticker.css,
              }}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};
